import { FacetList, FacetService } from '@spartacus/storefront';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsFacetService extends FacetService {
  getLinkParams(query: string): Params {
    let filtersQuery = super.getLinkParams(query).query;
    if (filtersQuery?.length) {
      filtersQuery = filtersQuery.replace(/"/g, "'");
    }
    return {
      facetFilters: filtersQuery?.length ? filtersQuery : null,
      currentPage: 0,
    };
  }

  getTranslationKey(facetName: string): string {
    return `rrs.facet.${facetName}`;
  }
}
